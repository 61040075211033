* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: #1F2633;
}

.light {
  background-color: #C6D9E1;
}