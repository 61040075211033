/* Randomize Button */

.button {
    background-color: #53ffaa;
    border-radius: 50%;
    display: flex;
    align-items: center;
    padding: 15px;
    border: 0;
    cursor: pointer;
}

.button:hover {
    background-color: #41a171;
}

.button > img {
    width: 18px;
    height: 18px;
}

button.light {
    background-color: #cf2379;
}

/* Theme Changing Button */

button.light:hover {
    background-color: #cf5391;
}

.switchButton {
    background-color: #303A48;
    color: #c6d9e1;
    border-radius: 4px;
    text-align: center;
    padding: 16px;
    width: 20%;
    margin: 0 auto;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: .2em;
    border: 0;
    font-weight: 600;
}

.switchButton:hover {
    background-color: #273242;
}

.switchButton.light {
    color: #303A48;
    /* background-color: #53a2c4; */
    background-color: #a5bbc4;
}

.switchButton.light:hover {
    background-color: #bacfd7;
}