.container {
    background-color: #303A48;
    border-radius: 8px;
    padding: 30px;
    margin: auto;
    width: 50%;
    position: relative;
    max-width: 520px;
    min-width: 300px;
}

.container.light {
    background-color: #a5bbc4;
}

.buttonWrap {
    text-align: right;
    margin: 25vh auto 20px auto;
    width: 50%;
    max-width: 520px;
    min-width: 300px;
}

.random-button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
}

.container > img {
    width: 100%;
    padding: 15px 0;
}

.divider {
    width: 100%;
    pointer-events: none;
    user-select: none;
}