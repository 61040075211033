@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');

.advice-text {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    margin: 15px auto;
}

.advice-text > q {
    font-size: 22px;
    color: #C6D9E1;
    font-weight: 600;
}

.advice-text > h2 {
    margin-bottom: 20px;
    font-size:12px;
    font-weight: 500;
    color: #63ffaa;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.advice-text.light > q {
    color: #303A48
}

.advice-text.light > h2 {
    color: #cf2379
}